.FooterBody {
  height: 50px;
  top: 94%;
  font-size: 13px;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  width: 100%;
  padding-right: 10px;
  margin-right: 50px
}

.FooterBodyLink, .FooterBodyLink:hover {
  color: black;
  text-decoration: none;
  text-emphasis: none;
}

@media screen and (max-width: 1600px) {
  .FooterBody {
    position: relative
  }
}
