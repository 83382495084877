.voteModal {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.voteModalBody {
  box-sizing: border-box;
  display: grid;
  margin: .5rem;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, .2);
  border-radius: .5rem;
  border-color: black;
  border-style: solid;
  border-width: 0.25px;
  background-color: white;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  padding: 5px;
}

.electionsContainer{
  width: 200px;
}

.voteIcon {
  margin-right: 10px;
}

.voteModalSignInBody {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 305px;
  height: 225px
}

.electionName {
  font-size: 18px;
}

.voteContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: left;
  margin-left: 0;
  margin-top: 2.5px;
}

img.vote {
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-left: 2px;
  margin-right: 2px;
}

.voteModalHeader {
  display: flex;
  justify-content: space-between;
}

.modalClose {
  font-size: 1.5rem;
  font-style: bold;
  cursor: pointer;
  line-height: 1.5rem;
  padding-top: 0rem;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 0;
  width: 1.5rem;
  height: 1.5rem
}

.modalClose:hover {
  color: grey;
  cursor: pointer;
  background-color: rgb(169, 202, 255);
  border-radius: 40px
}