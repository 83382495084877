.SettingsBody {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: sticky;
    width: 100%;
    background-color: white;
}

.SettingsChildWrapper {
    width: 100%;
    margin-top: 75px
}

@media screen and (max-width: 600px) {
    .SettingsChildWrapper{
        margin-top: 135px
   }
  }