img.rectangle-image {
  width: 100%;
  height: 200px;
  border-style: solid;
  border-width: 1px;
}

img.rectangle-image-reddit {
  width: 100%;
  height: 100%;
}

iframe.rectangle-iframe {
  width: 100%;
  height: 200px;
  border-style: solid;
  border-width: 1px;
}

img.circle-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-width: 0.25px;
  margin-bottom: 1px;
  margin-right: 1px;
}

.post-circle-images-and-small-text-container {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  align-items: center;
  max-width: 350px
}

.post-small-text-container {
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-left: 5px;
}

.post-small-text {
  font-family: "Helvetica Neue";
  font-size: 11px;
  font-weight: 600;
  line-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-circle-images-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
}

.post-vote-container {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.post-vote {
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.post-vote-count {
  font-size: 14px;
  padding-right: 3px
}

.post-title a {
  font-family: "Helvetica Neue";
  font-size: 21px;
  font-weight: 600;
  line-height: 25px;
  text-decoration: none;
}

.post-published-date {
  padding: 5px 0px;
  font-family: "Helvetica Neue";
}

.post-description {
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.post:hover {
  background: whitesmoke
}

.youtube-image-container {
  position: relative;
  cursor: pointer

}

.spinner-rectangular {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.youtube-play {
  position: absolute;
  height: 45px;
  width: 65px;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: block;
  margin: auto;
}


/*img.circle-image-youtube-video{*/
/*width: 40px;*/
/*height: 40px;*/
/*border-radius: 50%;*/
/*border-width: 0.25px;*/
/*margin-top: 10px;*/
/*margin-left: 10px;*/

/*position: absolute;*/
/*left: 0;*/
/*top: 0;*/
/*right: 0;*/
/*bottom: 0;*/

/*display: block;*/
/*}*/

@media screen and (min-width: 400px) {

  .post {
    display: inline-block;
    margin: 10px 0px;
    width: 350px;
    text-align: left;
  }

  .post a, h3 {
    color: black;
  }
}

@media screen and (max-width: 399px) {

  .post {
    display: inline-block;
    margin: 10px 0px;
    max-width: 90%;
    text-align: left;
  }

  .post a, h3 {
    color: black;
  }

}