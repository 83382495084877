.billboardContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    position: sticky;
    z-index: 0;
    margin-top: 60px;
}

.billboardNav {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    position: sticky;
    z-index: 2;
    margin-top: 5px;
    margin-bottom: 5px;
    background: white;
}

.billboardPages {
    width: 100%;
    margin-top: 100px
}

.elections-winners-header {
    width:100%;
    max-width:1200px;
    text-align: center;
    font-size: 25px;
    line-height: 40px;
    font-weight: 600;
}

@media screen and (max-width: 600px) {
    .billboardPages{
        margin-top: 160px
   }
}