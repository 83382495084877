a {
  text-decoration: none !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", sans-serif;
  background: white;
}

button {
  cursor: pointer;
}