.Search {
  width: 350px;
  min-width: 315px; 
  border: silver 1px solid; 
  border-radius: 5%; 
  margin-right: 1%; 
  margin-left: 2%;
  align-items: center; 
  justify-content: space-between
}

.SearchableSelect {
  width: 20%; 
  min-width: 90px; 
  height: 90%; 
  background: transparent; 
  border: none; 
  outline: none; 
  margin-left: 2% 
}

.SearchableSelect:hover {
  cursor: pointer
}

.SearchableSelectOption{
  cursor: pointer
}

.SearchableSelectOption:hover {
  cursor: pointer;
}

@media screen and (max-width: 501px) {
  .Search {
    display: none
  }
}
