@media screen and (min-width: 1050px) {
  .container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 50px;
    grid-row-gap: 10px;
    grid-template-rows: auto;
  }
}

@media screen and (max-width: 1049px) {
  .container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 50px;
    grid-row-gap: 10px;
    grid-template-rows: auto;
  }
}

@media screen and (max-width: 800px) {
  .container {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 50px;
    grid-row-gap: 10px;
    grid-template-rows: auto;
  }
}

@media screen and (max-width: 500px) {
  .container {
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 50px;
    grid-row-gap: 10px;
    grid-template-rows: auto;
    justify-items: center;
  }
}
