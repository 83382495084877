.postCardListContainer {
  display: flex;
  justify-content: space-around;
}

.emptyList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 95px;
  z-index: 0
}

.emptyList h3 {
  margin-left: 80px;
}

.post-card-body:hover{
  background: whitesmoke
}

.postCardList {
  width: 100%;
}

@media screen and (min-width: 1000px) {
  .postCardList {
    display: grid;
    grid-column-gap: 40px;
    grid-template-rows: auto;
    align-items: start;
    z-index: 0;
    align-content: center;
    justify-content: center;
  }
}

@media screen and (min-width: 1400px) {
  .postCardList {
    grid-template-columns: auto auto auto;
  }
}


@media screen and (min-width: 1000px) and (max-width: 1399px) {
  .postCardList {
    grid-template-columns: auto auto;
  }
  .emptyList h3 {
    margin-left: 40px;
  }
}


@media screen and (max-width: 999px) {
  .postCardList {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0
  }
  
  .emptyList h3 {
    margin-left: 0px;
  }
}
