.auth-handler {
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  position: sticky;
  z-index: 2;
  top: 120px;
  margin-top: 5px;
  margin-bottom: 5px;
  background: white;
}