.winner-post-card-caption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #4688f1;
  height: 60px;
}

.winner-post-card-election-image {
  border-radius: 100%;
  height: 40px;  
  margin-left: 10px
}

.winner-post-card-election-name {
  font-weight: 700;
  color: white;
  font-size: 20px
}

.winner-post-card-current-winner-subtitle {
  font-weight: 400;
  color: white;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.winner-post-card-election-link-image {
  color: white;
  margin-right: 10px;
  font-size: 45px
}

.winner-post-card-election-link-image:hover{
  color: whitesmoke;
  cursor: pointer
}

