.signInContainer {
  margin: 0 auto;
  max-width: 1100px;
  display: flex;
  justify-content: space-around;
}

h2.tagline {
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  text-align: center;
}

.tutorialVideo {
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}

.tutorialVideoFrame {
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}

.signInWithProviderContainer {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.signInWithProviderContainer .styledAuth {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.signInContainer {
  margin-top: 100px;
}

.signInModalContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.signInModalButton {
  margin-top: 7%;
  cursor: pointer
}

.callToAction {
  margin-bottom: 10%;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700
}

@media screen and (min-width: 1500px) {

  .pitchContainer {
    width: 60%;
  }

}

@media screen and (min-width: 1250px) and (max-width: 1499px) {

  .pitchContainer {
    width: 60%;
  }

}
@media screen and (min-width: 930px) and (max-width: 1249px) {

  .pitchContainer {
    width: 60%;
  }

}


@media screen and (max-width: 929px) {

  h2.tagline {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700
  }

}


@media screen and (min-width: 750px) and (max-width: 929px) {

  .pitchContainer {
    margin-left: 2.5%;
    width: 60%;
    margin-right: 2.5%
  }

}

@media screen and (max-width: 749px) {

  .pitchContainer {
    margin-top: 50px;
    width: 90%;
  }

  .signInContainer {
    text-align: center;
    align-items: center;
    flex-direction: column-reverse;
  }

}