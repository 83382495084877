.UserSettingsField {
  flex-direction: column;
}

.UserSettingsSubmitButton {
  margin-top: 10px;
}

/* To get hr to show */
hr {
  border:solid 1px black;
  width: 96%;
  color: #FFFF00;
  height: 1px;
}