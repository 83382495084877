.navigationBar {
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    background: white;
    height: 60px;
    border-bottom: 1px solid
}

.navigationBar nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 2%;
    width: 70%
}

img.user-av:hover {
    cursor: pointer
}