.vote-post-card-caption {
  display: flex;
  flex-direction: column;
}

.vote-post-card-bottom-caption {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
}

.vote-post-card-bottom-left-caption {
  width: 47%;
  text-align: right;
}

.vote-post-card-bottom-middle-caption {
  width: 6%;
  text-align: center;
}

.vote-post-card-bottom-right-caption {
  width: 47%;
  text-align: left;
}