.MixtapeBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: sticky;
    background-color: white;
    margin-top: 50px;
  }
  
  .MixtapePostcardListWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 225px;
    z-index: 0
  }
  
  .MixtapePostCardList {
    /* width: 40%;  */
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-top: 80px;
  }

  .MixtapePostcardListWrapperLoader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 225px;
    width: 100%;
    background: white 
  }

  
  .MixtapePostCardListLoader {
    width: 40%; 
    display: flex;
    flex-direction: column;
    align-items: center; 
    background-color: white;
    margin-top: 115px;
    z-index: 0
  }


  .MixtapePostCardListBody {
    display: flex;
    justify-content: space-around;
    background: white;
}

@media screen and (max-width: 600px) {
  .MixtapePostCardList{
      margin-top: 85px
 }
}