.ProfileBody {
  display: flex;
  justify-content: space-around;
  position: sticky;
  background-color: white;
  margin-top: 50px;
}

.MixtapeSourceTimelineBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  background-color: white;
  margin-top: 0px;
}

.MixtapeSourceTimelinePostcardListWrapper{
  display: flex;
  align-items: center;
  z-index: 0;
}

.ProfilePostcardListWrapper{
  display: flex;
  align-items: center;
  z-index: 0;
}

.ProfilePostCardList {
  z-index: 0
}