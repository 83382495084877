.GenericSettingsBody {
  display: flex;
  width: 100%;
  justify-content: center;
}

.GenericSettings {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  position: sticky;
  top: 100px;
}