.buttonBody {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.billboardNav {
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  position: sticky;
  z-index: 2;
  top: 120px;
  margin-top: 5px;
  margin-bottom: 5px;
  background: white;
  border: solid white 3px;
  
}

.settings-bar-button {
  width: 100%;
  height: 50px;
  margin: 5px;

  font-size: 14px;
  color: black;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;

  background: rgba(59,89,152, 1);
  box-shadow: rgba(0,0,0,0.1) 4px 4px 8px 0px;

  border: none;
  border-radius: 8px
}

.sign-in-button-image
{
  /* width: 220px;
  height: 50px; */
  margin: 0px;
  padding: 0px;

  font-size: 14px;
  color: white;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;

  border: none;
  /* border-radius: 8px */
  cursor: pointer;
}

.sign-in-button, 
.show-more-button, 
.update-button,
.create-button,
.unlink-button {
  width: 220px;
  height: 50px;
  margin: 0px;
  padding: 0px;

  font-size: 14px;
  color: white;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;

  background: rgba(59,89,152, 1);
  box-shadow: rgba(0,0,0,0.1) 4px 4px 8px 0px;

  border: none;
  border-radius: 8px
}
/*lookup material ui hover/clicks*/
.sign-in-button:hover, 
.show-more-button:hover, 
.update-button:hover,
.create-button:hover,
.unlink-button:hover {
  background: rgba(50,80,143, 1);

}

.sign-in-button:focus, 
.show-more-button:focus, 
.update-button:focus,
.create-button:focus,
.unlink-button:focus {
  background: rgba(41,71,134, 1);
  outline: none
}

.mixtape-source-button {
  min-width: 165px;
  max-width: 165px;
  width: 100%;
  height: 165px;
  margin: 0px;

  font-size: 12px;
  color: black;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;

  background: rgb(139, 157, 195);

  padding: 0 0 0 0;
  border: none;
  border-radius: 8px
}

.mixtape-source-button-header{
  display: flex;
  justify-content: space-between;
  align-items: center;

  background:  rgba(59,89,152, 1);

}

.mixtape-source-button-title {
  width: 60%;
  height: 45px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 12px;
  color: black;
  font-family: 'Open Sans Condensed';
}

.mixtape-source-button-title p {
  margin: 0px
}

.mixtape-source-button-subUnsub {
  width: 20%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button:hover {
  background: rgba(59,89,152, 1);
}


.mixtape-source-button-2 {
  height: 60px;
  width: 250px;
  border-width: 1px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  box-shadow: rgba(0,0,0,0.1) 4px 4px 8px 0px;
}

.mixtape-source-button-link{
  display: flex;
  flex-direction: row;
  
}

.mixtape-source-button-2-text-box {
  height: 40px;
  max-width: 165px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: -1px;
  margin-left: 5px
}


.mixtape-source-button-2-text-sub-box {
  height: 20px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mixtape-source-button-2-sub-unsub {
  margin-left: auto;
  align-self: center;
  height: 35px;
  background: rgb(250, 250, 250);
}

.mixtape-source-button-2:hover {
  background: whitesmoke;
}

.mixtape-source-button-2-avatar:hover,
.mixtape-source-button-2-text-box:hover,
.mixtape-source-button-2-sub-unsub:hover {
  cursor: pointer
}

.mixtape-source-button-2-sub-unsub:hover{
  background: rgb(225, 225, 225)
}